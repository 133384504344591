/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
// (window as any).__Zone_disable_customElements = true;
// (window as any).__Zone_disable_timers = true; // https://github.com/angular/zone.js/issues/1137
(window as any).__Zone_disable_requestAnimationFrame = true;
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['pointermove', 'pointerup'];
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['mouseout', 'mousemove', 'pointermove'];
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = [];
